import { useSelector } from 'react-redux';

import { pageNames } from '@atc/bonnet-paths';

import { useFeatures } from 'reaxl-features';

import { vdpResultsDuck } from '@/ducks/vdp';

const { VEHICLE_DETAILS } = pageNames;

export default function useIsIndexable(canonicalUrl, pageName) {
    const {
        seo_index_spotlight_vdp: [enableVdpSpotlightIndexing, { states }],
    } = useFeatures(['seo_index_spotlight_vdp']);

    // SEO test for indexing some spotlight VDP in certain states
    let indexableVDP = false;
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    if (enableVdpSpotlightIndexing) {
        const { premiumSpotlight, owner } = listing;
        const state = owner?.location?.address?.state;
        const includedStates = states?.states || [];
        indexableVDP = premiumSpotlight && includedStates.includes(state);
    }

    return canonicalUrl && (pageName !== VEHICLE_DETAILS || indexableVDP);
}
