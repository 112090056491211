import { objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpListingTypesCrawlPaths',
    initialState: {
        listingTypeData: {},
    },
});
