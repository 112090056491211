import { useSelector } from 'react-redux';

import Head from 'next/head';

import { useFeature } from 'reaxl-features';
import { useOptimizelySnippet } from 'reaxl-optimizely';

import currentPageNameDuck from '@/ducks/currentPageNameDuck';

export default function OptimizelySnippetLoader({ query = {}, url }) {

    const [disableThirdParty] = useFeature('disable_thirdparty');
    const pageName = useSelector(currentPageNameDuck.selectors.getDuckState);

    const [optimizelySnippet, preloadSnippet] = useOptimizelySnippet({
        pageName,
        query,
        thirdPartyEnabled: !disableThirdParty,
        url,
    });

    return optimizelySnippet && (
        <Head>
            {preloadSnippet && (
                <link
                    rel="preload"
                    href={optimizelySnippet}
                    as="script"
                    key="optimizelySnippetPreload"
                />
            )}
            <script
                async={false}
                key="optimizelySnippet"
                src={optimizelySnippet}
                type="text/javascript"
            />
        </Head>
    );
}
