import Head from 'next/head';

import { useBrand } from 'reaxl-brand';

export default function FontPreloads() {

    const { brand, brands } = useBrand();

    const { FORD_BRAND, KBB_BRAND } = brands;

    const getFonts = (fontBrand) => {
        switch (fontBrand) {
            case FORD_BRAND:
                return {
                    'ford-antenna-300': 'ford/ford-antenna-light.woff2', // ford landing only?
                    'ford-antenna-700': 'ford/ford-antenna-bold.woff2', // ford landing only?
                    'ford-antenna-400': 'ford/ford-antenna-regular.woff2', // ford landing only?
                    'open-sans-400': 'ford/open-sans-v29-latin-regular.woff2',
                    'open-sans-700': 'ford/open-sans-v29-latin-700.woff2',
                };
            case KBB_BRAND:
                return {
                    'open-sans-400': 'kbb/open-sans-v29-latin-regular.woff2',
                    'open-sans-700': 'kbb/open-sans-v29-latin-700.woff2',
                    'montserrat-500': 'kbb/montserrat-v24-latin-500.woff2',
                    'montserrat-700': 'kbb/montserrat-v24-latin-700.woff2',
                };
            default:
                return {
                    'roboto-400': 'atc/roboto-v30-latin-regular.woff2',
                    'roboto-700': 'atc/roboto-v30-latin-700.woff2',
                    'roboto-900': 'atc/roboto-v30-latin-900.woff2',
                };
        }
    };

    const preloads = Object.entries(getFonts(brand)).map(([key, href]) => (
        <link
            rel="preload"
            href={`/cars-for-sale/assets/fonts/${href}`}
            type="font/woff2"
            as="font"
            crossOrigin="anonymous"
            key={key}
        />
    ));

    return (
        <Head>
            {preloads}
        </Head>
    );
}
