import Head from 'next/head';

import { useFeature } from 'reaxl-features';

export default function CreditAppScriptLoader() {

    const [enableCreditApp, { env: drsEnv }] = useFeature('digital_retailing_suite');

    return enableCreditApp && (
        <Head>
            <script // eslint-disable-line @next/next/no-sync-scripts
                src={drsEnv}
                type="text/javascript"
                widget="embed"
                heightPolicy="dynamic"
            />
        </Head>
    );
}
