import Head from 'next/head';

import { useFeature } from 'reaxl-features';

export default function PixallPreload() {

    const [, { src: pixallSrc }] = useFeature('pixall');

    return typeof window !== 'undefined' && !window.Pixall && (
        <Head>
            <link
                key="pixallScript"
                rel="preload"
                href={pixallSrc}
                as="script"
            />
        </Head>
    );
}
