import { pageNames } from '@atc/bonnet-paths';
import { objectDuckCreator } from '@atc/modular-redux';

const { FYC_SITEMAP } = pageNames;

const sitemapContentDuck = objectDuckCreator({
    namespace: FYC_SITEMAP,
    storePrefix: `${FYC_SITEMAP}_`,
    store: 'content',
});

export default sitemapContentDuck;
