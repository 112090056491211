import { fetchJSON } from '@bonnet/next/fetch';

import { keyMappings, translateKeys } from '@atc/bonnet-parameters';
import { createAction, createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { brands } from 'reaxl-brand';

import processCrawlPath from '@/utilities/processCrawlPath';

import { srpDuckPreset } from '@/ducks/presets';

import srpAdditionalCrawlPathsDuck from './srpAdditionalCrawlPathsDuck';
import srpCitiesCrawlPathsDuck from './srpCitiesCrawlPathsDuck';
import srpListingTypesCrawlPathsDuck from './srpListingTypesCrawlPathsDuck';
import srpMakeCrawlPathsDuck from './srpMakeCrawlPathsDuck';
import srpModelCrawlPathsDuck from './srpModelCrawlPathsDuck';
import srpTrimCrawlPathsDuck from './srpTrimCrawlPathsDuck';
import srpYearCrawlPathsDuck from './srpYearCrawlPathsDuck';

const srpContentDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'content',
    initialState: {
        isIndexedZip: undefined,
        metaDescription: '',
        windowTitle: '',
        openGraphDescription: '',
        openGraphImage: '',
        openGraphSiteName: '',
        openGraphType: '',
        openGraphUrl: '',
        locationHeading: '',
        srpSelfServiceSeo: {},
        summaryContentData: {},
        makeSummaryData: {},
        editorials: [],
        sycUrl: '',
        queryId: '',
    },
}).extend({
    types: [
        'SET_CONTENT',
    ],
    creators: ({ types }) => ({
        clearCrawlPaths: () => async (dispatch) => {
            dispatch(srpAdditionalCrawlPathsDuck.creators.clear());
            dispatch(srpCitiesCrawlPathsDuck.creators.clear());
            dispatch(srpListingTypesCrawlPathsDuck.creators.clear());
            dispatch(srpMakeCrawlPathsDuck.creators.clear());
            dispatch(srpModelCrawlPathsDuck.creators.clear());
            dispatch(srpTrimCrawlPathsDuck.creators.clear());
            dispatch(srpYearCrawlPathsDuck.creators.clear());
        },
        setContent: createAction(types.SET_CONTENT),
        fetchCrawlPaths: ({
            brand,
            channel,
            query,
            enableCrawlPath,
            enableIndexedLocations,
            enableTrimData,
            enabledCityDataWithZip,
            isNational,
        }) => async (dispatch, getState) => {

            const isKbbBranded = brand === brands.KBB_BRAND;
            const isFordBranded = brand === brands.FORD_BRAND;
            const translatedKeys = translateKeys(query, { target: 'lsc' });

            const { makeCode, modelCode, trimCode, year, vehicleStyleCode, driveGroup, minPrice, maxPrice, fuelTypeGroup, listingType } = translatedKeys;

            const hasMultipleSelections = (key) => Array.isArray(query[key]) && query[key].length > 1;
            const multipleCrawlParams = [
                'makeCode',
                'seriesCode',
                'modelCode',
                'seriesCode',
                'trimCode',
                'listingType',
                'vehicleStyleCode',
                'bodyStyleSubtypeCode',
                'featureCode',
                'listingFeature',
                'fuelTypeGroup',
                'mpgRange',
            ];
            const requestCrawlPath = !keyMappings.filter((x) => multipleCrawlParams.includes(x.lsc))
                .find((x) => hasMultipleSelections(x.lsc) || hasMultipleSelections(x.cs));

            const isTrimUrl = !!trimCode && !year;
            const isYearUrl = !!year && !trimCode;
            const isYmmtUrl = !!(year && makeCode && modelCode && trimCode);
            const isAllCarsUrl = !makeCode && !modelCode && !trimCode;
            const isMakeUrl = !!(makeCode && !modelCode && !trimCode);
            const isModelUrl = !!(makeCode && modelCode && ((enableCrawlPath && enableTrimData) || !trimCode));

            const disableNonCitiesCrawlpath = !isKbbBranded && !!(vehicleStyleCode && (year || makeCode || modelCode || trimCode));
            const disableTrimList = isTrimUrl || isYearUrl || isYmmtUrl || disableNonCitiesCrawlpath;

            const shouldFetchTrimsData = (enableCrawlPath && enableTrimData) || !disableTrimList;
            const shouldFetchYearsData = isKbbBranded || (isModelUrl && !(isYearUrl || disableNonCitiesCrawlpath));

            const fbaShouldRenderCitiesList = !!(!makeCode && !vehicleStyleCode && !driveGroup && !minPrice && !maxPrice && !fuelTypeGroup && !year);

            const shouldFetchCrawlpaths = () => {
                if (isKbbBranded && (listingType === 'NEW' || listingType === 'CERTIFIED')) {
                    return false;
                }
                return requestCrawlPath;
            };

            const crawlPathsRequestOptions = {
                circuitBreaker: {
                    id: 'lsc_crawlpath',
                    timeout: 3000,
                    resetTimeout: 300000,
                    fallback: {},
                },
                query: { ...query },
                headers: {
                    'X-fwd-svc': 'atc',
                },
            };

            const nationalQuery = {
                ...crawlPathsRequestOptions?.query,
            };
            if (isNational) {
                delete nationalQuery.city;
                delete nationalQuery.state;
                delete nationalQuery.zip;
                delete nationalQuery.location;
                delete nationalQuery.searchRadius;
            }

            const nationalCrawlPathOptions = {
                ...crawlPathsRequestOptions,
                query: nationalQuery,
            };

            switch (crawlPathsRequestOptions.query.vehicleStyleCode) {
                case 'SUVCROSS': {
                    crawlPathsRequestOptions.query.vehicleStyleCode = 'SUV';
                    break;
                }
                case 'VANMV': {
                    crawlPathsRequestOptions.query.vehicleStyleCode = 'VANS';
                    break;
                }
                default:
                    break;
            }

            if (shouldFetchCrawlpaths()) {
                crawlPathsRequestOptions.query.facetMinCount = 3;
                if (channel === 'ATC,PREVIEW') {
                    crawlPathsRequestOptions.query.channel = 'ATC';
                } else {
                    crawlPathsRequestOptions.query.channel = channel;
                }

                const fetches = {};
                if (!disableNonCitiesCrawlpath && isAllCarsUrl) {
                    fetches.makesCrawlPaths = fetchJSON('/rest/lsc/crawl/makes', crawlPathsRequestOptions);
                }

                if ((enableCrawlPath && enabledCityDataWithZip) || fbaShouldRenderCitiesList) {
                    const citiesOptions = isNational ? nationalCrawlPathOptions : crawlPathsRequestOptions;
                    fetches.citiesCrawlPaths = fetchJSON('/rest/lsc/crawl/cities', citiesOptions);
                }

                if (!disableNonCitiesCrawlpath && isMakeUrl) {
                    fetches.modelCrawlPaths = fetchJSON('/rest/lsc/crawl/models', crawlPathsRequestOptions);
                }

                if (!disableNonCitiesCrawlpath && isModelUrl) {
                    if (crawlPathsRequestOptions.query.modelCode && shouldFetchTrimsData) {
                        fetches.trimCrawlPaths = fetchJSON('/rest/lsc/crawl/trims', crawlPathsRequestOptions);
                    }
                }

                if (makeCode) {
                    if (!isFordBranded && !disableNonCitiesCrawlpath) {
                        fetches.listingTypesCrawlPaths = fetchJSON('/rest/lsc/crawl/listingtypes', crawlPathsRequestOptions);
                    }

                    if (shouldFetchYearsData) {
                        fetches.yearCrawlPaths = fetchJSON('/rest/lsc/crawl/modelyears', crawlPathsRequestOptions);
                    }
                }

                const fetchResults = await Promise.all(Object.values(fetches));
                const pageData = fetchResults.reduce((acc, data, index) => {
                    const key = Object.keys(fetches)[index];
                    acc[key] = data;
                    return acc;
                }, {});

                if (pageData && Object.hasOwn(pageData, 'makesCrawlPaths')) {
                    dispatch(srpMakeCrawlPathsDuck.creators.setKeys({ makesData: pageData.makesCrawlPaths }));
                }

                await processCrawlPath({
                    brand,
                    data: pageData,
                    dispatch,
                    enableIndexedLocations,
                    isIndexedZip: srpContentDuck.selectors.getIsIndexedZip(getState()),
                    srpSelfServiceSeo: srpContentDuck.selectors.getSrpSelfServiceSeo(getState()),
                    query,
                });
            }
        },
    }),
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_CONTENT: {
                const { pageData = {} } = action.payload;

                return {
                    ...state,
                    metaDescription: pageData.metaDescription || pageData.defaultMetaDescription,
                    metaKeywords: pageData.metaKeywords || '',
                    metaRobots: pageData.metaRobots,
                    windowTitle: pageData.windowTitle || pageData.title,
                    // openGraph content for Facebook graph
                    openGraphDescription: pageData.openGraphDescription,
                    openGraphImage: pageData.openGraphImage,
                    openGraphSiteName: pageData.openGraphSiteName,
                    openGraphTitle: pageData.windowTitle || pageData.title,
                    openGraphType: pageData.openGraphType,
                    openGraphUrl: pageData.openGraphUrl,
                    // selfService content data for meta tags
                    selfServiceWindowTitle: pageData.selfServiceWindowTitle,
                    selfServiceMetaDescription: pageData.selfServiceMetaDescription,
                    selfServiceLocationHeading: pageData.selfServiceLocationHeading,

                    locationHeading: pageData.locationHeading,
                    summaryContentData: pageData.summaryContentData,
                    makeSummaryData: pageData.makeSummaryData,
                    editorials: pageData.editorialContent,
                    sycUrl: pageData.sycUrl,
                    queryId: pageData.queryId,
                };
            }

            default:
                return state;
        }
    },
    selectors: () => ({
        getEditorials: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ editorials }) => editorials,
        )),
        getMakeSummaryData: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ makeSummaryData }) => makeSummaryData,
        )),
        getLocationHeading: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ locationHeading }) => locationHeading,
        )),
        getIsIndexedZip: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).isIndexedZip),
        getQueryId: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).queryId),
        getSrpSelfServiceSeo: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).srpSelfServiceSeo),
    }),
});

export default srpContentDuck;
