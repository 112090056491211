import { objectDuckCreator } from '@atc/modular-redux';

import { asfDuckPreset } from '@/ducks/presets';

const sfpContentDuck = objectDuckCreator({
    ...asfDuckPreset,
    store: 'content',
});

export default sfpContentDuck;
