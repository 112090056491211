import { objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

const srpAdditionalCrawlPathsDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpAdditionalCrawlPaths',
    initialState: {
        pricingData: {},
        conditionData: {},
        stylesData: {},
    },
});

export default srpAdditionalCrawlPathsDuck;
