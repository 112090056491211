import { getReference } from '@atc/bonnet-reference';

export const getCrawlpathDataMaps = async (makeCode = '') => {
    const { payload: makeCodes = [] } = await getReference('makeCode');
    const { payload: vehicleStyleCodes = [] } = await getReference('vehicleStyleCode');
    const { payload: modelCodes = [] } = makeCode ? await getReference('modelCode', { makeCode }) : {};

    const makesMap = {};
    const vehicleStylesMap = {};
    const modelsMap = {};

    makeCodes.forEach(({ code, name }) => {
        makesMap[code] = name;
    });

    vehicleStyleCodes.forEach(({ code, shortName }) => {
        // pluralize body style names
        shortName = shortName.split(' / ').map((style) => style + 's').join(' / ');
        vehicleStylesMap[code] = shortName;
    });

    modelCodes.forEach(({ code, name }) => {
        modelsMap[code] = name;
    });

    return { makesMap, vehicleStylesMap, modelsMap };
};
