import querystring from 'querystring';
import url from 'url';

import _get from 'lodash/get';

import { testUrlLocation } from '@/utilities';

import { BonnetModule } from '@bonnet/next/modules';

import { getKeys } from '@atc/bonnet-parameters';
import { getPath, pageNames } from '@atc/bonnet-paths';
import { getIndexedLocationFilters } from '@atc/bonnet-reference';
import { stringDuckCreator } from '@atc/modular-redux';

import { brands } from 'reaxl-brand';

import getPathSRP from '@/utilities/getPathSRP';

import pricesWithCanonicalUrl from '@/config/pricesWithCanonicalUrl.json';
import fordSeoUrl from '@/config/seo-config-url/branded/ford';
import urlToUpdateCanonical from '@/config/urlToUpdateCanonical';

import {
    srpContentDuck,
} from '@/ducks/srp';

const { SEARCH_FORM, SEARCH_RESULTS, FORD_LANDING, VEHICLE_DETAILS, FYC_SITEMAP } = pageNames;

const NEW_LISTING_TYPE = 'NEW';
const USED_LISTING_TYPE = 'USED';
const CERT_LISTING_TYPE = 'CERTIFIED';
const ALL_LISTING_TYPE = 'all';

const hasSingleSelection = (selection = []) => (Array.isArray(selection) ? selection.length === 1 : true);

// In the case that multiple fuel types are selected, this function will determine if the combo of
// selections should be allowed in the canonical
const isAllowedFuelType = (selections = []) => {
    // each allowed combo should be a new array member
    const allowedCombos = [
        ['HYB', 'ELE'],
    ];

    // Allow single selection or one of the allowed combos above ONLY
    return hasSingleSelection(selections) || allowedCombos.some(
        (allowedCombo) => allowedCombo.every((fuelType) => selections.includes(fuelType))
            && selections.every((selection) => allowedCombo.includes(selection))
    );
};

// Checks if listingType excludes a type
const listingTypeExcludes = (listingType, excludedItem) => listingType && ((Array.isArray(listingType) && listingType.length > 1) || (!Array.isArray(listingType) && listingType !== excludedItem));

// Check if there's only 1 particular seller type
const isSingleSellerType = (sellerType, typeToCheck) => hasSingleSelection(sellerType) && sellerType === typeToCheck;

const determineSelfServiceLocationLogic = async ({ ctx, brand, locationIndexValue }) => {
    let indexedZip = true;
    let locationData;
    const city = ctx.query.location?.city?.replace(/\s+/g, '-')?.toLowerCase() || ctx.query.city?.replace(/\.+/g, '')?.replace(/\s+/g, '-')?.toLowerCase();
    const state = ctx.query.location?.state?.toLowerCase() || ctx.query.state?.toLowerCase();

    if (locationIndexValue === 'none') {
        indexedZip = false;
    } else if (locationIndexValue === 'all') {
        // If locationIndexValue === 'all', add location data to canonical url regardless of whether the location is included in the
        // indexed locations list
        locationData = { city, state };
    } else {
        const { success } = await getIndexedLocationFilters(ctx.query, brand, locationIndexValue);
        ctx.data.isIndexedZip = success;
        ctx.store.dispatch(srpContentDuck.creators.setKeys({ isIndexedZip: success }));

        if (!success) {
            indexedZip = false;
        } else {
            locationData = { city, state };
        }
    }

    return {
        indexedZip,
        locationData,
    };
};

function isUsedOnlyCanonicalCriteriaSelected({ query, data }) {
    // Checks fields that could impact the canonical url to make sure only used is selected
    // Fields that have multiple values selected won't impact canonical
    const { listingTypes, listingType, sellerTypes, sellerType, vehicleStyleCodes, vehicleStyleCode, driveGroup, engineCodes, engineCode,
        fuelTypeGroup, minPrice, maxPrice, mpgRanges, mpgRange, makeCodeList, makeCode, year, allListingType } = query;
    const { brand } = data;

    if (brand !== brands.FORD_BRAND && ((listingTypeExcludes(listingType, USED_LISTING_TYPE)) || listingTypeExcludes(listingTypes, USED_LISTING_TYPE))) {
        return false;
    }

    if (brand === brands.FORD_BRAND && allListingType !== ALL_LISTING_TYPE) {
        return false;
    }

    if (isSingleSellerType(sellerTypes, 'p') || (isSingleSellerType(sellerType, 'p'))) {
        return false;
    }

    const isSingleSelection = [makeCodeList, makeCode, vehicleStyleCodes, vehicleStyleCode, driveGroup, engineCodes, engineCode, fuelTypeGroup].some(hasSingleSelection);
    if (isSingleSelection) {
        return false;
    }
    if ((minPrice && !maxPrice) || (maxPrice && !minPrice)) {
        return false;
    }

    return !(year || mpgRanges || mpgRange);
}

const FORD_SEO_FILTER_KEYS = ['vehicleUseType', 'vehicleStyleCodes', 'vehicleStyleCode', 'makeCodeList', 'makeCode', 'modelCodeList', 'modelCode',
    'seriesCodeList', 'seriesCode', 'minPrice', 'maxPrice', 'fuelTypeGroup', 'transmissionCodes', 'transmissionCode', 'engineCodes', 'engineCode',
    'listingTypes', 'listingType', 'driveGroup', 'moneyBackGuarantee', 'vehicleExchange'];

const KBB_SEO_EXCLUDED_FILTERS = ['sellerType', 'vehicleStyleCode', 'driveGroup', 'engineCode', 'fuelTypeGroup', 'price',
    'featureCode', 'moneyBackGuarantee', 'extColorSimple', 'commercialVehicles', 'year', 'makeCode', 'seriesCode', 'modelCode',
    'trimCode'];

function hasLocationData({ city = '', state = '', zip = '' }) {
    return (city && state) || zip;
}

function fordBrandShouldHaveCanonical(query = {}) {

    if (hasLocationData(query)) {

        // Does it have SEO filters
        // If location data and Seo filters are present, then it should not have a canonical URL
        return !Object.keys(query)
            .filter((key) => FORD_SEO_FILTER_KEYS.includes(key))
            .some((key) => (hasSingleSelection(query[key])));
    }
    return true;
}

function shouldBeIndexed(query = {}, brand, onlyUseNationalData) {
    let shouldIndex = true;
    const listingType = query.listingType || query.allListingType;

    // KBB: Base SRPs for each listing type (excluding certified) should be indexed (i.e /cars-for-sale/{ listingType })
    //      Base location urls for 'used' and 'all' listing types should be indexed (i.e /cars-for-sale/{ used | all }/{ listingType })
    if (brand === brands.KBB_BRAND) {
        // Certified SRPs are not indexed
        if (listingType === CERT_LISTING_TYPE) {
            return false;
        }

        // New listing type: only base url is indexed
        if (listingType === NEW_LISTING_TYPE) {
            if (!onlyUseNationalData) {
                return false;
            }

            Object.keys(query).forEach((key) => {
                if ([...KBB_SEO_EXCLUDED_FILTERS].includes(key)) {
                    shouldIndex = false;
                }
            });

            return shouldIndex;
        }

        if (!onlyUseNationalData && (listingType === USED_LISTING_TYPE || listingType === ALL_LISTING_TYPE)) {
            Object.keys(query).forEach((key) => {

                if ([...KBB_SEO_EXCLUDED_FILTERS].includes(key)) {
                    shouldIndex = false;
                }
            });
        }
    }

    // if the query includes more than one selection for make, model or trim, the url should not be indexed.
    const target = 'lsc';

    for (const key of [getKeys('makeCode')[target], getKeys('modelCode')[target], getKeys('trimCode')[target]]) {
        if (query[key] && !hasSingleSelection(query[key])) {
            return false;
        }
    }

    // if dealer sellerType is in the query, it should not be indexed
    if (query.sellerType === 'd') {
        return false;
    }

    return shouldIndex;
}

function buildCanonicalQuery(query) {
    const canonicalQueryExceptions = {
        electricMileRange: query.electricMileRange,
        mpgRanges: query.mpgRanges || query.mpgRange,
        fuelTypeGroup: query.fuelTypeGroup,
    };

    if (!hasSingleSelection(canonicalQueryExceptions.mpgRanges)) {
        delete canonicalQueryExceptions.mpgRanges;
    }

    if (!hasSingleSelection(canonicalQueryExceptions.mpgRange)) {
        delete canonicalQueryExceptions.mpgRange;
    }

    // ensure we have electric mile range and that it is not multiple
    if (!hasSingleSelection(canonicalQueryExceptions.electricMileRange)) {
        delete canonicalQueryExceptions.electricMileRange;
    }

    if (!isAllowedFuelType(canonicalQueryExceptions.fuelTypeGroup)) {
        delete canonicalQueryExceptions.fuelTypeGroup;
    }

    // stringify the optional canonical search
    let canonicalSpecificSearch = querystring.stringify(canonicalQueryExceptions);

    if (canonicalSpecificSearch) {
        canonicalSpecificSearch = `?${canonicalSpecificSearch}`;
    }

    return canonicalSpecificSearch;
}

const CanonicalUrlModule = new BonnetModule({
    duck: stringDuckCreator({
        store: 'canonicalUrl',
    }),

    ctxMiddleware: ({ duck }) => async (ctx) => {
        let canonicalUrl = '';

        const { req, query, data, srpSelfServiceSeo, asPath = '', match } = ctx;

        const { brand, currentUrl } = data;
        const {
            enable_srp_canonical_fallback: [enableSrpCanonicalFallback],
            enable_indexed_locations: [enableIndexedLocations],
        } = ctx.useFeatures([
            'enable_srp_canonical_fallback',
            'enable_indexed_locations',
        ]);

        // only add zip data if there is no location data in url
        const onlyUseNationalData = !(await testUrlLocation(match.url));

        switch (ctx.pageName) {
            case SEARCH_FORM: {
                // TODO: BONNET NEXT - This needs the whole path as it is canonical?
                canonicalUrl = await getPath(SEARCH_FORM, {}, {
                    absolute: true,
                    brand,
                    req,
                    search: false,
                    basePath: true,
                });

                break;
            }

            case SEARCH_RESULTS: {

                const {
                    canonical_url: selfServiceCanonical,
                    no_index: selfServiceNoIndex,
                    withLocation,
                    indexable_locations: indexableLocations = 'high',
                } = srpSelfServiceSeo || {};

                const locationIndexValue = enableIndexedLocations ? indexableLocations : 'all';

                if (!selfServiceNoIndex) {
                    if (selfServiceCanonical) {
                        if (withLocation || onlyUseNationalData) {
                            canonicalUrl = selfServiceCanonical;
                        } else {
                            let indexedZip = true;
                            let locationData;
                            // Only location pages with zip code or city center zip code in list OR location pages with only USED
                            // should be indexed
                            if (ctx.query.zip) {
                                ({ indexedZip, locationData } = await determineSelfServiceLocationLogic({ ctx, brand, locationIndexValue }));
                            }
                            if (indexedZip && locationData) {
                                const { city, state } = locationData;
                                const [canonicalUrlPrefix, canonicalQueryParams] = selfServiceCanonical.split('?');
                                const queryParams = canonicalQueryParams ? `?${canonicalQueryParams}` : '';

                                canonicalUrl = canonicalUrlPrefix;

                                if (brand === brands.ATC_BRAND) {
                                    canonicalUrl = canonicalUrl.replace('/used-cars', '');
                                }

                                if (!onlyUseNationalData) {
                                    // add / when using locationData['city, state'] field
                                    canonicalUrl += `/${city}-${state}`;
                                }
                                canonicalUrl += queryParams;
                            }
                        }
                    } else if (shouldBeIndexed(query, brand, onlyUseNationalData) && (enableSrpCanonicalFallback || brand !== brands.ATC_BRAND)) {
                        // Only run canonical fallback logic when there are issues with the Altezza elasticache cluster
                        // urls with more than one make/model/trim selected should not be indexed

                        let canonicalQuery = { ...query };
                        const listingTypes = _get(canonicalQuery, 'listingType', []);

                        // Do not index if is CPO listings page
                        // This rule does not affect on Ford site
                        if (brand !== brands.FORD_BRAND && listingTypes.includes(CERT_LISTING_TYPE)) {
                            canonicalUrl = null;
                            break;
                        }

                        const canonicalSpecificSearch = buildCanonicalQuery(query);

                        // The SEO canonical URL should round up or down to the next increment of price to
                        // prevent search engines from indexing "cars under" pages that have little search volume.
                        // Do nothing when both a max price and a min price
                        if (canonicalQuery.minPrice
                            || (canonicalQuery.maxPrice && !pricesWithCanonicalUrl.find((el) => el.price === parseInt(canonicalQuery.maxPrice, 10)))) {
                            delete canonicalQuery.minPrice;
                            delete canonicalQuery.maxPrice;
                        } else {
                            canonicalQuery = {
                                ...ctx.query,
                                ...(canonicalQuery.maxPrice && { maxPrice: parseInt(canonicalQuery.maxPrice, 10) }),
                            };
                        }

                        let indexedZip = true;
                        // Only location pages with zip code in list OR location pages with only USED
                        // should be indexed
                        // The Ford site is an exception to this rule.
                        // National pages (no location in url) is also an exception
                        if (brand === brands.FORD_BRAND) {
                            indexedZip = onlyUseNationalData || fordBrandShouldHaveCanonical(query);

                        } else if (brand !== brands.FORD_BRAND && !onlyUseNationalData) {
                            if (locationIndexValue === 'none') {
                                indexedZip = false;
                            } else {
                                const { success } = await getIndexedLocationFilters(query, 'atc', locationIndexValue);
                                ctx.data.isIndexedZip = success;
                                ctx.store.dispatch(srpContentDuck.creators.setKeys({ isIndexedZip: success }));

                                if (!success && !isUsedOnlyCanonicalCriteriaSelected(ctx) && !onlyUseNationalData) {
                                    indexedZip = false;
                                }
                            }
                        }

                        // Do not index if not appropriate zip/listing type
                        if (indexedZip) {
                            const currentPath = decodeURIComponent(asPath.split('?')[0]);
                            // Force specific KBB pages to have ATC canonicals for SEO testing purposes on KBB
                            // Check if current url needs to have ATC canonical
                            // canonicalUrl is a forced ATC if page is kbb.com, matches specific URL and has KBB brand
                            const isMatchedUrlToUpdate = urlToUpdateCanonical.kbb.some((urlToUpdate) => currentPath.includes(urlToUpdate));
                            const isForcedAtcBrand = brand === brands.KBB_BRAND && isMatchedUrlToUpdate && (url.parse(currentUrl)?.host || '').includes('kbb');

                            // Use bonnet-paths to encode the seo portions of the query
                            // if page is SEO test KBB page, pass ATC brand instead
                            if (onlyUseNationalData) {
                                delete canonicalQuery.city;
                                delete canonicalQuery.state;
                                delete canonicalQuery.zip;
                            }

                            canonicalUrl = await getPathSRP(canonicalQuery, {
                                absolute: true,
                                brand: isForcedAtcBrand ? brands.ATC_BRAND : brand,
                                req,
                                search: false,
                            });

                            // exclude zipcode in canonicalUrl
                            canonicalUrl = canonicalUrl?.replace(`-${canonicalQuery.zip}`, '');

                            // add the canonicalUrl with the allowed srp canonical search string
                            canonicalUrl = `${canonicalUrl}${canonicalSpecificSearch}`;

                            // replace kbb host in canonicalUrl with autotrader if page is SEO test KBB page
                            canonicalUrl = isForcedAtcBrand ? canonicalUrl.replace('kbb', 'autotrader') : canonicalUrl;

                            const byOwnerParam = '/used-cars?sellerTypes=p';
                            if (brand === brands.ATC_BRAND && canonicalUrl.endsWith(byOwnerParam)) {
                                canonicalUrl = canonicalUrl.replace(byOwnerParam, '/by-owner');
                            }

                            // if there aren't enough filters to bump out gasoline from path do not index
                            // /all-cars is atc only, and we don't want to index any of those either.
                            if (canonicalUrl.includes('/gasoline') || canonicalUrl.includes('/all-cars')) {
                                canonicalUrl = null;
                            }
                        }
                    } else {
                        canonicalUrl = null;
                        ctx.data.isIndexedZip = false;
                        ctx.store.dispatch(srpContentDuck.creators.setKeys({ isIndexedZip: false }));
                    }
                }
                break;
            }

            case VEHICLE_DETAILS: {
                const vdpCanonicalQuery = {
                    listingId: query?.listingId,
                };

                canonicalUrl = await getPath(VEHICLE_DETAILS, vdpCanonicalQuery, {
                    absolute: true,
                    brand,
                    req,
                    useUpdatedVdpPath: true,
                });
                break;
            }

            case FORD_LANDING: {
                canonicalUrl = process.env.NODE_ENV === 'development' ? fordSeoUrl.nonProd.landingPage : fordSeoUrl.prod.landingPage;
                break;
            }

            case FYC_SITEMAP: {
                const sitemapPageId = query?.sitemapPageId || 'index';

                canonicalUrl = await getPath(FYC_SITEMAP, { sitemapPageId }, {
                    absolute: true,
                    req,
                    brand,
                    search: false,
                });

                break;
            }

            default:
        }

        ctx.store.dispatch(duck.creators.setString(canonicalUrl));
        ctx.data.canonicalUrl = canonicalUrl;
    },
});

export default CanonicalUrlModule;
