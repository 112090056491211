import { objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

const srpCitiesCrawlPathsDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpCitiesCrawlPaths',
    initialState: {
        citiesData: {},
    },
});

export default srpCitiesCrawlPathsDuck;
