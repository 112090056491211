import { objectDuckCreator } from '@atc/modular-redux';

import { flpDuckPreset } from '@/ducks/presets';

const flpContentDuck = objectDuckCreator({
    ...flpDuckPreset,
    store: 'content',
});

export default flpContentDuck;
