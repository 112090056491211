import _get from 'lodash/get';

import { createSelector, DuckSelector } from '@atc/modular-redux';

import {
    calculateDownPayment,
    getAppliedDealerSettings,
    getDealInfo,
    getSelectedPricingDetails,
} from 'atc-js';

import { getEcommLinkWithWalletData } from 'reaxl-wallet';

import { resultsDuckCreator } from 'axl-ducks';

import paymentsDuck from '@/ducks/paymentsDuck';
import { vdpDuckPreset } from '@/ducks/presets';
import userDuck from '@/ducks/userDuck';

import CanonicalUrlModule from '@/modules/CanonicalUrlModule';

const DEFAULT_EMPTY_INVENTORY = {};

const vdpResultsDuck = resultsDuckCreator({
    ...vdpDuckPreset,
}).extend({
    selectors: () => ({
        getVdpActiveInventory: new DuckSelector((selectors) => createSelector(
            selectors.getActiveInventory,
            ([activeInventory]) => activeInventory || DEFAULT_EMPTY_INVENTORY
        )),
        isUserInvolved: new DuckSelector(() => createSelector(
            (state) => paymentsDuck.selectors.getMyWalletInteractions(state),
            (interactions) => {
                const { paymentEmphasis, hasUpdatedWallet, hasUpdatedNDSCalc } = interactions;
                return paymentEmphasis || hasUpdatedWallet || hasUpdatedNDSCalc;
            }
        )),
        getDealInfo: new DuckSelector((selectors) => createSelector(
            selectors.getVdpActiveInventory,
            selectors.isUserInvolved,
            (state) => paymentsDuck.selectors.getDuckState(state) || {},
            (state) => _get(state, 'interestRates', {}),
            (listing, isUserInvolved, payments, nationalRates) => {
                if (isUserInvolved && payments && listing) {
                    const {
                        id: listingId,
                        paymentServices: {
                            dealerSettings = {},
                        } = {},
                        pricingDetail = {},
                        type: vehicleCondition = 'used',

                    } = listing;

                    const {
                        interactions: walletInteractions = {},
                    } = payments;

                    const {
                        paymentDetails,
                        downPayment,
                        selectedPricingDetails,
                        price,
                    } = getSelectedPricingDetails(payments, listingId, dealerSettings, pricingDetail);

                    const { downPaymentPercent, downPayment: downPaymentValue, ...restSettings } = getAppliedDealerSettings({ dealerSettings, walletInteractions, price, payments, nationalRates });

                    const paymentsWithSettings = {
                        ...payments,
                        ...restSettings,
                    };

                    if (downPayment) {
                        paymentsWithSettings.downPayment = downPayment;
                    } else {
                        // Fall back downPayment If PS won't return any downPayment for any reason there will be one in nds as well.
                        paymentsWithSettings.downPayment = calculateDownPayment(price, { downPayment: downPaymentValue, downPaymentPercent });
                    }

                    const { deal = {} } = getDealInfo(selectedPricingDetails, paymentDetails, vehicleCondition, paymentsWithSettings, false);
                    return deal;
                }
                return {};
            }
        )),
        getEcommHrefWithWalletData: new DuckSelector((selectors) => createSelector(
            selectors.getVdpActiveInventory,
            (state) => paymentsDuck.selectors.getEcommercePaymentsInfo(state) || {},
            (state) => userDuck.selectors.getZip(state) || '',
            (state) => CanonicalUrlModule.duck.selectors.getDuckState(state),

            (listing, ecommercePaymentsInfo, userZip, canonicalUrl) => {
                if (listing?.ecommWebsite?.href) {
                    const baseEcommWebsite = listing.ecommWebsite.href;
                    return getEcommLinkWithWalletData(baseEcommWebsite, ecommercePaymentsInfo, userZip || listing.zip, canonicalUrl);
                }
                return null;
            }
        )),
    }),
});

export default vdpResultsDuck;
