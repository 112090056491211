export default {
    kbb: [
        '/cars-for-sale/used/pittsburgh-pa-15219',
        '/cars-for-sale/used/tesla/model-3',
        '/cars-for-sale/used/gmc/sierra-2500',
        '/cars-for-sale/new/gmc/sierra-1500?trimCodeList=15SIPU4WD|AT4X',
        '/cars-for-sale/used/volvo/s60?trimCodeList=S60|T8 Polestar',
        '/cars-for-sale/new/2022/land-rover/range-rover',
        '/cars-for-sale/used/tesla/model-y/san-francisco-ca-94103',
        '/cars-for-sale/used/ford/bronco/tampa-fl-33602',
        '/cars-for-sale/used/hybrid/toyota/camry',
        '/cars-for-sale/certified/honda/accord',
    ],
};
