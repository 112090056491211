import Head from 'next/head';

import { useBrand } from 'reaxl-brand';

export default function FacebookMetaTags() {
    const { isBrand, brands } = useBrand();

    if (isBrand(brands.KBB_BRAND)) {
        return (
            <Head>
                <meta
                    property="fb:app_id"
                    content="184365531583362"
                />
            </Head>
        );
    }

    // defaults to brand === 'atc'
    return (
        <Head>
            <meta
                property="fb:admins"
                content="100000201817615"
            />
            <meta
                property="fb:admins"
                content="57701950"
            />
        </Head>
    );

}
