import { objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpContentDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'content',
});

export default vdpContentDuck;
