import { useSelector } from 'react-redux';

import { pageNames } from '@atc/bonnet-paths';

import { currentPageNameDuck } from '@/ducks';

import { flpContentDuck } from '@/ducks/flp';
import { sfpContentDuck } from '@/ducks/sfp';
import sitemapContentDuck from '@/ducks/sitemapDuck';
import { srpContentDuck } from '@/ducks/srp';
import { vdpContentDuck } from '@/ducks/vdp';

export default function usePageContent() {

    const pageName = useSelector(currentPageNameDuck.selectors.getDuckState);
    const { SEARCH_FORM, SEARCH_RESULTS, VEHICLE_DETAILS, FORD_LANDING, FYC_SITEMAP } = pageNames;

    const pageContent = useSelector((state) => {
        switch (pageName) {
            case SEARCH_RESULTS:
                return srpContentDuck.selectors.getDuckState(state);
            case VEHICLE_DETAILS:
                return vdpContentDuck.selectors.getDuckState(state);
            case SEARCH_FORM:
                return sfpContentDuck.selectors.getDuckState(state);
            case FORD_LANDING:
                return flpContentDuck.selectors.getDuckState(state);
            case FYC_SITEMAP:
                return sitemapContentDuck.selectors.getDuckState(state);
            default:
                return {};
        }
    });

    return pageContent;
}
