import { getIndexedLocationFilters, getMarketFromCityState } from '@atc/bonnet-reference';

const locationRegex = /^([A-Za-z\-.']+)(?:-)+([A-Za-z]{2})(?=-)(?=.\d)(?:-)+(\d{5})$/; // city-state-zip
const locationNozipRegex = /^([A-Za-z\-.']+)(?:-)+([A-Za-z]{2}$)/; // city-state

export const testUrlLocation = async (url) => {
    const splitPath = url.split('/');
    const locationSlug = splitPath.pop();

    const noZipLocationMatch = locationNozipRegex.test(locationSlug);
    const locationMatch = locationRegex.test(locationSlug);

    if (locationMatch || noZipLocationMatch) {
        const location = locationMatch ? locationSlug.slice(0, -6) : locationSlug; // drop the zip code if it matches the legacy pattern
        const state = location.slice(-3)?.replace('-', '').toUpperCase();
        const city = location.slice(0, -3);

        // confirm the slug is a known city-state by searching indexed locations and markets data
        const gilRes = await getIndexedLocationFilters({ city, state });

        if (gilRes.success) {
            return gilRes.success;
        }

        const gmRes = await getMarketFromCityState({ city, state });

        return gmRes.success;
    }

    return locationMatch;
};

export default testUrlLocation;
