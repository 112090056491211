import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpTrimCrawlPaths',
    initialState: {
        trimData: {},
    },
}).extend({
    selectors: () => ({
        getTrimsData: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (trimData) => trimData,
        )),
    }),
});
