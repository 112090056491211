import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpYearCrawlPaths',
    initialState: {
        yearsData: {},
    },
}).extend({
    selectors: () => ({
        getYearsData: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (yearsData) => yearsData,
        )),
    }),
});
