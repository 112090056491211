import { objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpCrawlPathDataMaps',
    initialState: {
        makesMap: {},
        vehicleStylesMap: {},
        modelsMap: {},
    },
});
